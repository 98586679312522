var columns = [{
  title: "日期",
  dataIndex: "createdatatime",
  key: "createdatatime",
  scopedSlots: {
    customRender: "createdatatime"
  }
}, {
  title: "CBK发放数量",
  dataIndex: "num",
  key: "num",
  scopedSlots: {
    customRender: "num"
  }
}, {
  title: "CBK渠道",
  dataIndex: "typename",
  key: "typename",
  scopedSlots: {
    customRender: "typename"
  }
}];
export { columns };